import React, { Component } from "react";
import Layout from "../components/Layout";
import Cover from "react-video-cover";
import Video from "../img/404-tbwa.mp4";
import { Link } from "gatsby";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 100;
  }
`;

class Thankyou extends Component {
  state = {
    resizeNotifier: () => {}
  };

  render() {
    const videoOptions = {
      src: Video,
      autoPlay: true,
      muted: true,
      loop: true
    };

    return (
      <Layout>
        <Wrapper>
          <div
            className="inner"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%",
              color: "white",
              zIndex: "1"
            }}
          >
            Thank you we will be in touch soon{" "}
            <Link to="/">Home</Link>
          </div>
          <Cover
            videoOptions={videoOptions}
            remeasureOnWindowResize
            getResizeNotifier={resizeNotifier => {
              this.setState({
                resizeNotifier
              });
            }}
          />
        </Wrapper>
      </Layout>
    );
  }
}

export default Thankyou;
